/*
CallCare styles
*/
@font-face{font-family:'Aileron';src:url('../fonts/Aileron-Bold.woff2') format('woff2');font-weight:900;font-display:swap}
@font-face{font-family:'Aileron';src:url('../fonts/Aileron-Medium.woff2') format('woff2');font-weight:500;font-display:swap}
@font-face{font-family:'Aileron';src:url('../fonts/Aileron-Regular.woff2') format('woff2');font-display:swap}
@font-face{font-family:'Aileron';src:url('../fonts/Aileron-Italic.woff2') format('woff2');font-style:italic;font-display:swap}
@font-face{font-family:'Aileron';src:url('../fonts/Aileron-SemiBold.woff2') format('woff2');font-weight:600;font-display:swap}
/* General Layout */
html,body{margin:0;font-family:'Aileron';font-size:100%;font-weight:400;scroll-behavior:smooth;overflow-x:hidden}
img{border:0;vertical-align:middle}
fieldset{border:0;padding:0;margin:0}
img{max-width:100%}
small{font-size:85%}
/* General Styles */
h1, h2, h3, h4, h5, h6{margin:0;padding:0;font-size:inherit;font-weight:inherit}
p{margin:0;padding:0}
strong{font-weight:600}
p{}
a{color:#ec4b3a}
a.reversed, a.learnmore.underline:hover{color:#000}
a:hover{color:#d03727;text-decoration:none}
a.reversed:hover{color:#ec4b3a}
a.readmore, span.back{display:block;font-size:0.875em;font-weight:600}
a.readmore.underline{text-decoration:underline}
span.back:before{content:"\f104" !important}
a.readmore:not(.underline):before, .footer .footer-block ul.menu li a:before, .header .header-content .main-menu ul li.parent .dropdown .dropdown-block ul.services li a:before, span.back:before{content:"\f105";margin-right:0.5rem;font-family:"Font Awesome 5 Free"}
a.readmore:hover, span.back:hover{color:#ec4b3a;font-weight:900}
a:focus{outline:none;text-decoration:none}
a.button, button.alm-load-more-btn, .form-wrapper .form-actions input[type="submit"]{display:inline-block;padding:0.5rem 1.875rem;background:#ec4b3a;border:0;border-radius:1.875rem;color:#fff;line-height:1.25em;cursor:pointer}
a.button:hover, button.alm-load-more-btn:hover, .form-wrapper .form-actions input[type="submit"]:hover{color:#fff;box-shadow:0 3px 6px rgba(236,75,58,.5)}
button{vertical-align:top}
button.alm-load-more-btn:disabled{display:none}
ul.menu{margin:0;padding:0;list-style:none}
.section-title span, .content-section .thank-you h1{display:block;font-size:1.875em;color:#000;font-weight:600;line-height:1.25em}
.section-title p{font-size:1.25em;color:#000;line-height:1.25em}
.text-white .section-title span{color:#fff}
.text-white .section-title p{color:#fff}
.section-title.small span{font-size:1.5em}
span.back{font-size:1em;color:#ec4b3a;cursor:pointer}
.video-container{position:relative;height:0;padding-bottom:56.25%;overflow:hidden}
.video-container iframe{position:absolute;top:0;left:0;width:100%;height:100%;border:0;border-radius:0}
input:focus{outline:none}
.mt-1{margin-top:0.9375rem !important}
.mb-1{margin-bottom:0.9375rem !important}
.mt-2{margin-top:1.875rem !important}
.mb-2{margin-bottom:1.875rem !important}
.mt-3{margin-top:3.75rem !important}
.mb-3{margin-bottom:3.75rem !important}
.mt-5{margin-top:5.625rem !important}
.mb-5{margin-bottom:5.625rem !important}
.pt-1{padding-top:0.9375rem !important}
.pb-1{padding-bottom:0.9375rem !important}
.pt-2{padding-top:1.875rem !important}
.pb-2{padding-bottom:1.875rem !important}
.pt-3{padding-top:3.75rem !important}
.pb-3{padding-bottom:3.75rem !important}
.pt-5{padding-top:5.625rem !important}
.pb-5{padding-bottom:5.625rem !important}
.position-initial{position:inherit}
.wider-row{margin:0 -3.75rem}
/* Hero Section */
.hero-section{position:relative;background-size:cover;background-position:top center;background-repeat:no-repeat}
.hero-section.shadowed:after{display:block;content:"";position:absolute;bottom:0;width:100%;height:25rem;background:-webkit-linear-gradient(to top, rgba(255,255,255,1.00), transparent 100%);background:linear-gradient(to top, rgba(255,255,255,1.00), transparent 100%)}
.header{z-index:3;padding-top:1.875rem}
.header .header-content .top-menu ul li, .header .header-content .main-menu ul li{display:inline-block;margin:auto 1.875rem auto 0;font-size:1em;line-height:1em}
.header .header-content .top-menu ul li:last-of-type, .header .header-content .main-menu ul li:last-of-type{margin-right:0}
.header .header-content .top-menu ul li a, .header .header-content .main-menu ul li a:not(.readmore), .header .header-content .top-menu span.telephone a{color:#000}
.header .header-content .top-menu ul li a:hover, .header .header-content .main-menu ul li a:hover, .header .header-content .top-menu span.telephone a:hover{color:#ec4b3a}
.header .header-content .top-menu ul li:last-of-type{line-height:1.25em}
.header .header-content .top-menu ul li:last-of-type a{display:block;padding:0.5rem 1.875rem;background:#ec4b3a;border-radius:1.875rem;color:#fff}
.header .header-content .top-menu ul li:last-of-type a:hover{box-shadow:0 3px 6px rgba(236,75,58,.5)}
.header .header-content .top-menu span.telephone{font-size:1.5em;color:#000;line-height:1em}
.header .header-content .main-menu ul li.top-level > a{font-weight:900}
.header .header-content .main-menu .search-wrapper .search{width:2.125rem;height:2.125rem;overflow:hidden;transition:all 0.7s}
.header .header-content .main-menu .search-wrapper .search a{position:absolute;top:0;right:0;z-index:2;width:2.125rem;height:2.125rem;border-radius:100%;color:#000;text-align:center;line-height:2.125rem}
.header .header-content .main-menu .search-wrapper .search a:hover{color:#d03727}
.header .header-content .main-menu .search-wrapper.show .search{width:20rem;border-radius:2.125rem}
.header .header-content .main-menu .search-wrapper.show .search form{width:100%;z-index:-1}
.header .header-content .main-menu .search-wrapper.show .search a{display:none}
.header .header-content .main-menu .search-wrapper .search input[type="text"]{width:100%;height:2.125rem;padding-left:0.75rem;background:#fff;border:0;font-size:0.875em;color:#000;border-radius:2.125rem}
.header .header-content .main-menu .search-wrapper .search button{position:absolute;top:0;right:0;width:2.125rem;height:2.125rem;background:#fff;border:0;border-radius:100%;font-size:0;color:#000;cursor:pointer}
.header .header-content .main-menu .search-wrapper.show .search button{font-size:inherit}
.header .header-content .main-menu .search-wrapper .search button i{color:#000}
.header .header-content .main-menu .search-wrapper .search button:hover i{color:#d03727}
.header .header-content .mobile-search a, .header .mobile-header ul li a{display:block;width:2.125rem;height:2.125rem;background:#fff;border-radius:100%;color:#000;text-align:center;line-height:2.125rem}
.header .header-content .main-menu ul li.parent > a{display:block;padding:0.9375rem 0}
.header .header-content .main-menu ul li.parent:hover > a{transition:none;border-bottom:4px solid #000;margin-bottom:-4px}
.header .header-content .main-menu ul li.parent .dropdown{display:none;position:absolute;top:100%;left:0;z-index:2;width:100%;margin-top:3px;background:#fff}
.header .header-content .main-menu ul li.parent:hover .dropdown{display:block}
.header .header-content .main-menu ul li.parent .dropdown .dropdown-block{padding:0}
.header .header-content .main-menu ul li.parent .dropdown .dropdown-block .block-container{padding:1.875rem}
.header .header-content .main-menu ul li.parent .dropdown .dropdown-block .block-container.grey{background:#f7f7f7}
.header .header-content .main-menu ul li.parent .dropdown .dropdown-block span.title{display:table-caption;margin-bottom:1.875rem;font-size:1.5em;color:#000;font-weight:600;line-height:1em}
.header .header-content .main-menu ul li.parent .dropdown .dropdown-block p{font-size:0.875em;color:#000;line-height:1.25em}
.header .header-content .main-menu ul li.parent .dropdown .dropdown-block a.button{color:#fff}
.header .header-content .main-menu ul li.parent .dropdown .dropdown-block ul.services li{font-size:1em;font-weight:600;line-height:1.25em}
.header .header-content .main-menu ul li.parent .dropdown .dropdown-block ul.services li a{color:#ec4b3a}
.header .header-content .main-menu ul li.parent .dropdown .dropdown-block ul.services li a:hover{color:#000}
.header .mobile-header ul li{display:inline-block;margin-right:0.5rem}
.header .mobile-header ul li:last-of-type{margin-right:0}
.header .mobile-header ul li a{background:#ec4b3a;color:#fff}
.header .mobile-header ul li a i{line-height:2.125rem}
.header .mobile-header ul li a:hover{background:#d03727;color:#fff}
/* Reversed Hero */
.hero-section.reversed .header{padding-bottom:0.9375rem;background:#fff}
.hero-section.reversed .header .header-content .main-menu ul li.parent .dropdown{margin-top:-0.6875rem}
.hero-section.reversed .internal-hero{margin-top:0;padding-top:20rem;background-size:60% auto;background-position:center right;background-repeat:no-repeat}
/* Hero Content */
.hero-content{position:relative;z-index:2;margin:5.625rem 0}
.hero-content .boxen span.title{margin-bottom:1.875rem;font-size:3.125em;color:#000;font-weight:500;line-height:1em}
.hero-footer{position:relative;z-index:2;background:rgba(255,255,255,0.5)}
.hero-footer .boxen h1{display:block;margin-bottom:1.875rem;font-size:1.875em;color:#000;font-weight:900;line-height:1.25em}
.hero-footer .boxen h1 a{color:#000}
.hero-footer .boxen h1 a:hover{color:#ec4b3a}
.hero-footer .boxen p{font-size:1.25em;color:#000;line-height:1.25em}
/* Solution Grid */
.solutions-grid .solution .wrapper{background:#f7f7f7}
.solutions-grid .solution .boxen{padding:1.875rem}
.solutions-grid .solution .boxen span.title{display:block;margin-bottom:0.9375rem;font-size:1.25em;color:#000;font-weight:600;line-height:1.25em}
.solutions-grid .solution .boxen p{font-size:0.875em;color:#000;line-height:1.25em}
/* Services Grid */
.services-list.shadowed{position:relative;background-size:cover;background-repeat:no-repeat;background-position:top center;background-color:#f4e9a9}
.services-list.shadowed:before{display:block;content:"";position:absolute;top:0;width:100%;height:15rem;background:-webkit-linear-gradient(to bottom, rgba(255,255,255,1.00), transparent 100%);background:linear-gradient(to bottom, rgba(255,255,255,1.00), transparent 100%)}
.services-list.shadowed:after{display:block;content:"";position:absolute;bottom:0;width:100%;height:15rem;background:-webkit-linear-gradient(to top, rgba(255,255,255,1.00), transparent 100%);background:linear-gradient(to top, rgba(255,255,255,1.00), transparent 100%)}
.services-list.shadowed > .container{position:relative;z-index:2}
.services-list .overview .main-content span.title{display:block;margin-bottom:1.875rem;font-size:1.5em;color:#000;font-weight:600;line-height:1.25em}
.services-list .overview .main-content p{font-size:1em;color:#000;line-height:1.25em}
.services-list .services-grid .service .wrapper{background:#f7f7f7}
.services-list .services-grid .service .boxen{padding:1.875rem}
.services-list .services-grid .service .boxen span.title{display:table-caption;margin-bottom:0.9375rem;font-size:1.5em;color:#000;font-weight:600;line-height:1em}
.services-list .services-grid.larger .service .boxen span.title{display:block;font-size:1.875em}
.services-list .services-grid .service .boxen span.title a{color:#000}
.services-list .services-grid .service .boxen span.title a:hover{color:#ec4b3a}
.services-list .services-grid .service .boxen p{font-size:0.875em;color:#000;line-height:1.25em}
.content-section.text-white .services-list .services-grid .service .boxen p{color:#fff}
/* Latest Content */
.latest-content .insights-hub .wrapper, .content-section .half-section .image .wrapper, .featured-case-study .image .wrapper{background-size:cover;background-repeat:no-repeat;background-position:center}
.latest-content .insights-hub .boxen, .featured-case-study .boxen{padding:1.875rem;background:#fff}
.latest-content #ajax-load-more{width:100%}
.latest-content .article{padding-top:1.875rem}
.latest-content .articles-list.reversed .article .article-wrapper{background:#fff}
.latest-content .articles-list.reversed .article .article-wrapper .mb-auto{padding:1.875rem 1.875rem 0 1.875rem}
.latest-content .article span.category{display:block;margin-bottom:0.9375rem;font-size:0.75em;color:#818181;font-weight:600;line-height:1em}
.latest-content .article span.category span:after{content:" | "}
.latest-content .article span.category span:last-of-type:after{content:""}
.latest-content .article span.category a{color:#818181}
.latest-content .insights-hub .boxen span.title a:hover, .latest-content .article span.category a:hover, .latest-content .article span.title a:hover, .side-content .article span.title a:hover{color:#ec4b3a}
.latest-content .insights-hub .boxen span.title, .latest-content .article span.title, .side-content .article span.title{display:block;margin-bottom:0.9375rem;font-size:1.25em;color:#000;font-weight:600;line-height:1em}
.latest-content .insights-hub .boxen span.title a, .latest-content .article span.title a, .side-content .article span.title a{color:#000}
.latest-content .insights-hub .boxen p, .latest-content .article p, .side-content .article p{font-size:0.875em;color:#000;line-height:1.25em}
.latest-content .insights-hub .block-container span.title{font-size:1.5em}
.featured-case-study .image .wrapper .boxen{margin-top:10rem}
/* Clients */
.slider.slick-slider{padding:0 1.875rem;max-height: 300px}
.slider .slick-arrow{position:absolute;top:50%;z-index:2;margin-top:-1.125rem;width:2.25rem;height:2.25rem;background:#ec4b3a;border-radius:100%;font-size:1em;color:#fff;text-align:center;line-height:2.5rem;-moz-transition:all 0.3s ease-in;-webkit-transition:all 0.3s ease-in;-o-transition:all 0.3s ease-in;transition:all 0.3s ease-in}
.slider .slick-arrow.prev{left:0}
.slider .slick-arrow.next{right:0}
.slider .slick-arrow:hover{background:#19112c;color:#fff;cursor:pointer}
/* Timeline */
.slick-initialized .slick-track{display:flex;align-items:center;max-height: 300px}
.timeline .item .boxen span.date{display:block;padding:0.9375rem 0;font-size:1.875em;color:#000;font-weight:600;line-height:1em}
.timeline .item .boxen p{display:inline-block;width:75%;font-size:0.875em;color:#000;line-height:1.25em}
.timeline .item .hline{position:relative;width:100%;height:6px;background:#ec4b3a}
.timeline .item .hline span.circle{position:absolute;top:0;width:1.5rem;height:1.5rem;margin:-0.5875rem 0 0 -0.5875rem;background:#ec4b3a;border-radius:100%}
.timeline .item .icon{padding:0.9375rem 0}
/* Footer */
.footer{padding:3.75rem 0;background:#f7f7f7}
.footer .footer-block span.title{display:block;margin-bottom:0.875rem;font-size:1.5em;color:#000;font-weight:600;line-height:1.25em}
.footer .footer-block p{font-size:1em;color:#000;line-height:1.25em}
.footer .footer-block ul.menu li{font-size:1em;font-weight:600;line-height:1.25em}
.footer .footer-block ul.social{margin:0;padding:0;list-style:none}
.footer .footer-block ul.social li{display:inline-block;margin-right:1.5rem;font-size:2em}
.footer .footer-block.sign-up p{font-size:0.875em}
.footer .footer-block.sign-up form input[type="email"]{width:100%;height:2.125rem;padding-left:0.75rem;background:#fff;border:0;font-size:0.875em;color:#000;border-radius:2.125rem}
.footer .footer-block.sign-up form input[type="submit"]{display:block;margin:.5rem 0 0 auto;padding:0;background:none;border:0;font-size:1em;color:#ec4b3a;font-weight:bold;line-height:1em;cursor:pointer}
.footer .footer-block.sign-up form input[type="submit"]:hover{color:#d03727}
/* Sub Footer */
.sub-footer{padding:0.875rem 0}
.sub-footer p{font-size:0.75em;color:#000;line-height:1.5em}
/* Internal Hero */
.internal-hero{margin-top:7.5rem}
.internal-hero .breadcrumb-section{padding:0.9375rem 3.75rem 0.9375rem 0.9375rem;background:#fff}
.internal-hero .breadcrumb-section:after{display:block;content:"";position:absolute;top:0;right:0;width:100vw;height:100%;background:#fff}
.internal-hero .breadcrumb-section .breadcrumb{position:relative;z-index:1;margin-bottom:0.9375rem;padding:0;background:none;font-size:1em;color:#000;line-height:1.25em}
.internal-hero .breadcrumb-section .breadcrumb a{margin-bottom:0.9375rem;padding:0;background:none;font-size:1em;line-height:1.25em}
.internal-hero .breadcrumb-section h1{position:relative;z-index:1;font-size:2.8125em;color:#000;font-weight:600;line-height:1.25em}
/* Content Section */
.content-section{padding:5.625rem 0;background:#fff}
.content-section.grey, .latest-content.grey{padding:1.875rem 0;background:#f4f5f6}
.content-section.dark-grey{background:#818181}
.content-section.red{background:#ec4b3a}
.content-section .half-section .image .boxen{padding:1.875rem;background:#fff}
.content-section .half-section .image .row.mt-auto{padding-top:10rem}
.content-section.grey .half-section .image .boxen, .content-section.grey .readmore.position-absolute, .latest-content.grey .readmore.position-absolute{background:#f4f5f6}
.content-section .half-section .image .boxen span.title, .featured-case-study .image .wrapper .boxen span.title, .article-details h2, .side-content span.section-title{display:block;font-size:1.875em;color:#000;font-weight:600;line-height:1.25em}
.featured-case-study .image .wrapper .boxen span.title{margin-bottom:0.9375rem}
.content-section .half-section .image .boxen span.title a, .featured-case-study .image .wrapper .boxen span.title a{color:#000}
.content-section .half-section .image .boxen span.title a:hover, .featured-case-study .image .wrapper .boxen span.title a:hover{color:#ec4b3a}
.content-section .half-section .image .boxen p, .featured-case-study .image .wrapper .boxen p{font-size:0.875em;color:#000;line-height:1.25em}
.content-section .half-section .boxen{padding:1.875rem 0.9375rem}
.content-section .half-section .boxen span, .content-section .article-content .boxen span.title{display:block;margin-bottom:0.9375rem;font-size:1.25em;color:#000;font-weight:600;line-height:1.25em}
.content-section .half-section.smaller .boxen span.large{font-size:1.5em}
.content-section .half-section .boxen span a{color:#000}
.content-section .half-section .boxen span a:hover{color:#ec4b3a}
.content-section .half-section .boxen p, .content-section .boxen p, .content-section .article-content .boxen p, .article-details p{font-size:1.25em;color:#000;line-height:1.25em}
.content-section .half-section .boxen.information p{font-size:0.875em}
.content-section .half-section.smaller .boxen p{font-size:0.875em;font-weight:normal}
.content-section.text-white .boxen p{color:#fff}
.content-section .half-section .boxen.information ul{margin:1.875rem 0 3.75rem 0;padding:0;list-style:none}
.content-section .half-section .boxen.information ul li{margin-bottom:0.5rem;font-size:0.875em;color:#ec4b3a;font-weight:600;line-height:1.25em}
.content-section .half-section .boxen.information ul li:last-of-type{margin-bottom:0}
.content-section .half-section .boxen.information ul li:before{content:"\f105";margin-right:0.5rem;font-family:"Font Awesome 5 Free"}
/* Services Icons */
.services-list.icons .services-grid .service .boxen span.title{display:block;margin-bottom:1.875rem;font-size:1.25em}
/* FAQs */
.faqs .card{margin-bottom:1.875rem;border:0}
.faqs .card:last-of-type{margin-bottom:0}
.faqs .card .card-header{padding:0;background:none;border:0;cursor:pointer}
.faqs .card .card-header i.fas, .faqs .card .card-header span{font-size:1em;color:#ec4b3a;line-height:1.25em}
.faqs .card .card-header .accordion-title:not(.collapsed) i.fas:before{content:"\f068"}
.faqs .card .card-body{padding:0.9375rem 0 0 0}
.faqs .card .card-body p{font-size:0.875em;color:#000;line-height:1.25em}
/* Call to Action */
.call-to-action{padding:3.75rem 0;background:#c6cfd3}
.call-to-action .boxen span.title{display:block;margin-bottom:0.9375rem;font-size:1.875em;color:#000;font-weight:600;line-height:1.25em}
.call-to-action .boxen p{font-size:0.875em;color:#000;line-height:1.25em}
/* Insights Hub */
.categories span{font-size:1em;color:#000;line-height:1.25em}
.categories span:after{content:" | "}
.categories span:last-of-type:after{content:""}
.categories span a.active{color:#000;font-weight:600}
.latest-content .articles-list.full .article{margin-bottom:3.75rem;padding-top:0}
.readmore.position-absolute{bottom:0;left:0.9375rem;min-width:50%;background:#fff;padding:0.9375rem;font-size:1em}
.article-details img{width:auto;height:auto}
.article-details p{font-size:1em}
.article-details span.date{display:block;margin-bottom:1.875rem;font-size:1.125em;color:#000;font-weight:600;line-height:1.25em}
.side-content .article{margin-bottom:1.875rem}
.side-content .article:last-of-type{margin-bottom:0}
/* Numbered Sections */
.numbered-sections .numbered-item .boxen span.number{display:block;font-size:0.875em;color:#9fbc58;font-weight:600;line-height:1.25em}
.numbered-sections .numbered-item .boxen span.title{display:block;margin-bottom:0.9375rem;font-size:1.25em;color:#000;font-weight:600;line-height:1.25em}
.numbered-sections .numbered-item .boxen p{font-size:0.875em}
/* Shaping Triangle */
.shaping-your-solution .boxen img.icon{width:5.5rem}
.content-section.shadowed:before{display:block;content:"";position:absolute;top:0;width:100%;height:100%;background:-webkit-linear-gradient(to right, rgba(159,188,88,1.00), transparent 100%);background:linear-gradient(to right, rgba(159,188,88,1.00), transparent 100%)}
.content-section.shadowed#knowledge-and-speed-tab:before{background:-webkit-linear-gradient(to right, rgba(226,193,55,1.00), transparent 100%);background:linear-gradient(to right, rgba(226,193,55,1.00), transparent 100%)}
.content-section.shadowed#price-and-speed-tab:before{background:-webkit-linear-gradient(to right, rgba(236,75,58,1.00), transparent 100%);background:linear-gradient(to right, rgba(236,75,58,1.00), transparent 100%)}
.content-section .half-section .boxen #shaping-your-solution-tabs span.title{font-size:1.5em}
.tab-content{position:relative}
.tab-content span.revert{position:absolute;top:0;right:0;cursor:pointer;color:#000}
.tab-content span.revert:hover{color:#ec4b3a}
ul.shaping-icons{margin:0;border:0}
ul.shaping-icons li.nav-item{margin:0}
ul.shaping-icons li.nav-item a{padding:0;border:0}
ul.shaping-icons li.nav-item img{position:absolute;width:15.6%}
ul.shaping-icons li.nav-item img:hover{opacity:0.75}
ul.shaping-icons li.nav-item.nav-icon-price-and-knowledge img{top:37.5%;left:14.9%}
ul.shaping-icons li.nav-item.nav-icon-price-and-speed img{top:91.3%;left:42.4%}
ul.shaping-icons li.nav-item.nav-icon-knowledge-and-speed img{top:37.5%;left:69.5%}
span.instructions{font-size:0.875em;color:#000;line-height:1.25em;font-style:italic}
/* Staff Profiles */
.staff-profile{margin-bottom:3.75rem}
.staff-profile .wrapper{position:relative}
.staff-profile .boxen{margin-top:1.875rem}
.staff-profile .boxen span{display:block;font-size:1.5em;color:#000;line-height:1.25em}
.staff-profile .boxen span.name{font-weight:600}
.staff-profile .overlay{display:none !important;position:absolute;top:0;left:0;width:100%;height:100%;background:rgba(236,75,58,0.75)}
.staff-profile .wrapper:hover .overlay{display:flex !important}
.staff-profile .overlay .boxen{padding:0 1.875rem}
.staff-profile .overlay .boxen p{font-size:0.875em;color:#fff;line-height:1.25em}
.staff-profile .overlay .boxen ul.social-icons li{font-size:1.5em;color:#fff}
.staff-profile .overlay .boxen ul.social-icons li a{color:#fff}
/* Key */
.solutions-key .key-item span{display:block;font-size:1.875em;color:#000;font-weight:600;line-height:1.25em}
.solutions-key .key-item span a{color:#000}
.solutions-key .key-item span a:hover{color:#ec4b3a}
.solutions-key .key-item span a:after{display:block;content:"\f13a";margin-top:0.5rem;font-family:"Font Awesome 5 Free";color:#ec4b3a}
/* Case Study */
.article-content .vline{position:relative}
.article-content .vline:after{display:block;content:"";position:absolute;top:0;left:50%;width:1px;height:100%;background:#000}
.content-section .article-content .boxen span.title{font-size:1.875em}
.content-section .article-content .boxen p{font-size:0.875em}
.content-section .services-list .service .figure{position:relative}
.content-section .services-list .service .figure span{position:absolute;top:50%;transform:translateY(-50%);width:100%;font-size:3.75em;color:#fff;font-weight:600;line-height:1em}
/* Contact Us */
.locations-list .location{margin-bottom:1.875rem}
.locations-list .location .boxen{margin-bottom:1.875rem}
.locations-list .location .boxen span.title{display:block;margin-bottom:0.9375rem;font-size:1.5em;color:#000;font-weight:600;line-height:1.25em}
.locations-list .location .boxen p{font-size:1em;color:#000;line-height:1.25em}
.locations-list .location iframe{display:block;margin-top:1.875rem}
.locations-list .location a.directions{display:block;margin-top:0.9375rem}
/* Internal */
.content-section .main-content p{font-size:1em;color:#000;line-height:1.25em}
/* Forms */
.form-wrapper > div{width:100%}
.form-wrapper .form-item{margin-bottom:1.875rem}
.form-wrapper .form-item label{display:block;margin-bottom:0;font-size:1em;color:#fff;font-weight:500}
.form-wrapper .form-item input[type="text"], .form-wrapper .form-item input[type="email"], .form-wrapper .form-item input[type="tel"], .form-wrapper .form-item textarea, .form-wrapper .form-item select{display:block;width:100%;padding:0.9375rem 0;background:none;border:0;border-bottom:1px solid #fff;border-radius:0;font-size:1.125em;color:#fff;font-style:italic;-webkit-appearance:none}
.form-wrapper .form-item .wpcf7-form-control-wrap{display:block;position:relative}
.form-wrapper .form-item.type-select .wpcf7-form-control-wrap{background:#fff}
.form-wrapper .form-item.type-select .wpcf7-form-control-wrap select{position:relative;z-index:2;background:none}
.form-wrapper .form-item.type-select .wpcf7-form-control-wrap:after{display:block;content:"\f107";position:absolute;top:1.25rem;right:0;margin-right:0.5rem;font-family:"Font Awesome 5 Free";font-size:1.25em;color:#000;font-weight:bold;line-height:1em}
.form-wrapper .form-item select{padding-left:0.9375rem;padding-right:0.9375rem;background:#fff;border:0;color:#000}
.form-wrapper .form-item input::placeholder, .form-wrapper .form-item textarea::placeholder{opacity:1;color:#fff}
.form-item .form-check .form-check-input:active~.form-check-label::before
.custom-control-label:before{background-color:#dee2e6}
.form-wrapper .form-item .wpcf7-checkbox{display:block;position:relative;padding-left:1.25rem}
.form-wrapper .form-item .wpcf7-checkbox input[type="checkbox"]{position:absolute;margin-top:.3rem;margin-left:-1.125rem}
.form-wrapper .form-item .wpcf7-checkbox label span{margin-bottom:0}
.form-wrapper .form-actions input[type="submit"]{background:#fff;color:#000}
.form-wrapper .form-actions input[type="submit"]:hover{background:#ec4b3a;color:#fff}
.form-wrapper .steps span{display:block;margin-bottom:1.875rem}
.form-wrapper .steps span:before{display:block;content:"1";position:absolute;z-index:2;top:100%;left:50%;height:2rem;width:2rem;margin:-1rem 0 0 -1rem;background:#fff;border-radius:100%;color:#000;text-align:center;line-height:2rem}
.form-wrapper .steps span:after{display:block;content:"";position:absolute;top:100%;left:0;height:0.25rem;width:100%;background:#fff}
.form-wrapper .steps .second span:before{content:"2"}
.form-wrapper .steps .last span:before{content:"3"}
.form-wrapper .steps .first span:after{width:50%;left:auto;right:0}
.form-wrapper .steps .last span:after{width:50%}
.form-wrapper .screen-reader-response, .footer .footer-block.sign-up .screen-reader-response{display:none}
.form-wrapper .form-item .wpcf7-not-valid-tip, .form-wrapper .wpcf7-validation-errors, .footer .footer-block.sign-up .wpcf7-not-valid-tip, .footer .footer-block.sign-up .wpcf7-validation-errors{display:block;margin:0;padding:0.5rem 0.9375rem;background:#f44545;border:1px solid #cc3333;font-size:1em;color:#fff}
.form-wrapper .wpcf7-validation-errors, .footer .footer-block.sign-up .wpcf7-validation-errors{margin-top:1.875rem}
/* Thank You */
.internal-hero.thank-you{height:12rem}
/* Search Results */
.content-section.search .result{margin-bottom:1.875rem}
.content-section.search .result:last-of-type{margin-bottom:0}
.content-section.search .result span.title{display:block;font-size:1.5em;color:#000;font-weight:600;line-height:1.25em}
.content-section.search .result p{font-size:1em;color:#000;line-height:1.25em}
.content-section.search form.searchform{width:50%;margin-bottom:3.75rem}
.content-section.search form.searchform:after{content:"";display:block;clear:both}
.content-section.search form.searchform label{display:block;margin-bottom:0.25rem;font-size:0.875em;color:#666;font-weight:bold;line-height:1em}
.content-section.search form.searchform input[type="text"]{width:100%;height:2.5rem;padding:0 0.5rem;background:#fff;border:2px solid #ccc;font-size:0.875em;color:#666}
.content-section.search form.searchform button[type="submit"]{position:absolute;top:0;right:0;width:2.5rem;height:2.5rem;padding:0;background:none;border:0;color:#ec4b3a;line-height:1.875rem;cursor:pointer}
.content-section.search form.searchform button[type="submit"] i{vertical-align:top;line-height:2.5rem}
.content-section.search form.searchform button[type="submit"]:hover{background:#ec4b3a;color:#fff}
/*Styles for screen 1299px and lower*/
@media screen and (max-width:1299px){
    .wider-row{margin:0 -0.9375rem}
    .hero-section.reversed .internal-hero{padding-top:10rem;background-size:70% auto}
}
/*Styles for screen 1199px and lower*/
@media screen and (max-width:1199px){
    .container{max-width:96%}
    .header .header-content .top-menu ul li{margin-right:0.5rem}
    .header .header-content .main-menu .search-wrapper.show .search{width:15rem}
}
/*Styles for screen 1099px and lower*/
@media screen and (max-width:1099px){
    .header .header-content .top-menu span.telephone{display:none}
}
/*Styles for screen 991px and lower*/
@media screen and (max-width:991px){
    .header .header-content .top-menu ul li:last-of-type{display:none}
    .header .header-content .main-menu .search-wrapper .search a{display:none}
    .header .header-content .main-menu .search-wrapper .search a.mobile{display:block}
    .hero-content{margin:5.625rem 0}
    .latest-content .insights-hub .wrapper .row.mt-auto, .content-section .half-section .image .row.mt-auto{padding-top:7.5rem}
    .footer{padding-bottom:1.875rem}
    .footer .footer-block{margin-bottom:1.875rem}
    .content-section.grid{padding-bottom:3.75rem}
    .clients .clients-grid .case-studies-button{margin-top:1.875rem !important}
    .services-list.icons{padding-bottom:1.875rem}
    .services-list.icons .service{margin-bottom:3.75rem}
    .services-list.icons .cta{margin-top:0 !important}
    .latest-content .insights-hub.internal{margin-top:1.875rem}
    .hero-section.reversed .internal-hero{padding-top:7.5rem;background-size:80% auto}
    .content-section .half-section .boxen.information{padding-bottom:0}
    .content-section .half-section .boxen.information ul{margin-bottom:0}
    .content-section.locations	{padding-bottom:0}
    .locations-list .location{margin-bottom:3.75rem}
    .internal-hero.thank-you{height:5.625rem}
}
/*Styles for screen 820px and lower*/
@media screen and (max-width:820px){
    .header .header-content .top-menu ul li, .header .header-content .main-menu ul li{margin-right:0.5rem}
    .header .header-content .main-menu ul li.parent:hover > a{margin:0;border:0}
    .header .header-content .main-menu ul li.parent:hover .dropdown{display:none}
}
/*Styles for screen 767px and lower*/
@media screen and (max-width:767px){
    .container{max-width:100%}
    .hero-content{margin:3.75rem 0}
    .solutions-grid .solution, .services-grid .service{margin-bottom:1.875rem}
    .solutions-grid .solution:last-of-type, .services-grid .service:last-of-type{margin-bottom:0}
    .internal-hero{margin-top:5.625rem}
    .internal-hero .breadcrumb-section{padding:0.9375rem 0.9375rem 0 0.9375rem}
    .content-section{padding:3.75rem 0}
    .services-list.icons{margin-top:1.875rem !important}
    .faqs > .col-12{margin-bottom:0.9375rem}
    .faqs > .col-12:last-of-type{margin-bottom:0}
    .faqs .card{margin-bottom:0.9375rem}
    .call-to-action .cta{margin-top:1.875rem !important}
    .numbered-sections .numbered-item{margin-bottom:1.875rem}
    .numbered-sections .numbered-item:last-of-type{margin-bottom:0}
    .hero-section.reversed .internal-hero{padding-top:5.625rem;background-size:cover}
    .solutions-key .key-item{margin-bottom:1.875rem}
    .solutions-key .key-item:last-of-type{margin-bottom:0}
    .content-section .article-content .boxen{margin-bottom:1.875rem}
    .content-section .article-content .boxen:last-of-type{margin-bottom:0}
    .internal-hero.thank-you{height:3.75rem}
    .content-section .half-section .image .boxen{padding-bottom:0}
    .content-section .half-section .image .boxen span.title, .featured-case-study .image .wrapper .boxen span.title, .article-details h2, .side-content span.section-title{font-size:1.5em}
    .content-section.search form.searchform{width:100%}
    .form-wrapper .steps span:before{display:block;position:relative;top:auto;left:auto;margin:0 auto}
    .form-wrapper .steps span:after{display:none}
}
/*Styles for screen 576px and lower*/
@media screen and (max-width:576px){
    .locations-list .location{margin-bottom:1.875rem}
    .locations-list .location:last-of-type{margin-bottom:0}
    .locations-list .location iframe{height:15rem}
}
.shaping-your-solution .boxen img.icon{width:5.5rem}
.form-wrapper .form-call  a{color:#fff}
.form-wrapper .form-item  a{color:#fff;text-decoration:underline}
.screen-reader-response ul{display:none}
.screen-reader-response p{margin-bottom:20px;font-weight:bold}
.wpcf7-response-output{font-weight:bold}
span.wpcf7-not-valid-tip{font-weight:bold}
span.wpcf7-form-control.wpcf7-radio.wpcf7-not-valid{margin-right:10px}
.accreditation.col-4.col-sm.col-lg-3.my-auto{padding:0px 2px!important}

.user-info {
    display: flex;
    gap: 1rem;
    padding: 2rem 0;
    border-top: solid 1px #f7f7f7;
    margin-top: 3rem;
    align-items: flex-start;
}
.user-info .image-wrapper{
    flex: 0 0 auto;
}
.user-info img{
    border-radius: 100%;
    width: 80px;
    height: 80px;

}